import { render, staticRenderFns } from "./studyPage.vue?vue&type=template&id=1a3f0b6c&scoped=true&"
import script from "./studyPage.vue?vue&type=script&lang=js&"
export * from "./studyPage.vue?vue&type=script&lang=js&"
import style0 from "./studyPage.vue?vue&type=style&index=0&id=1a3f0b6c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3f0b6c",
  null
  
)

export default component.exports